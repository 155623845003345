<template>
  <!-- 课程配置 -->
  <div>
    <!-- 返回 -->
    <el-button style="margin-bottom: 18px" @click="$router.back()"
      >返回</el-button
    >
    <!-- 条件 -->
    <div
      style="
        display: flex;
        justify-center: end;
        background: #fff;
        padding: 10px 0 10px 10px;
        justify-content: space-between;
      "
    >
      <div
        style="
          line-height: 40px;
          font-size: 20px;
          font-weight: bolder;
          color: #1890ff;
          border-bottom: 3px solid #1890ff;
        "
      >
        {{ sortFieldType === 3 ? '待开展课程数排名' : '课程开出排名' }}
      </div>
      <el-form
        :inline="true"
        ref="conditionForm"
        :model="conditionForm"
        style="margin-bottom: -22px"
      >
        <el-form-item>
          <el-select
            v-model="conditionForm.sysOrgSchoolId"
            @change="inquireBtn"
          >
            <el-option label="全部学校" value=""></el-option>
            <el-option
              v-for="item in $store.state.public_data.sysSchoolDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetBtn">重置</el-button>
          <el-button type="primary" @click="inquireBtn">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-row>
      <el-table
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        @sort-change="changeSort"
      >
        <el-table-column
          width="80"
          label="排名"
          prop="rankings"
          sortable="custom"
        >
          <template slot-scope="row">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                line-height: 30px;
              "
              v-html="setIndexStyle(row.row.rankings)"
            ></div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="rankings" width="70" label="排名">
        </el-table-column> -->
        <el-table-column prop="sysOrgAreaName" label="区县"></el-table-column>
        <el-table-column prop="sysOrgSchoolName" label="学校">
        </el-table-column>
        <el-table-column prop="openLessonRate" width="200" label="开出率">
        </el-table-column>
        <el-table-column prop="openLessonCount" width="200" label="开出数">
        </el-table-column>
        <el-table-column prop="notOpenLessonCount" width="200" label="待开数">
        </el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="detailBtn(scope.row)" size="mini"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <el-dialog
      title="课程开展情况"
      :visible.sync="dialogVisible"
      width="1000px"
    >
      <!--  -->
      <el-row :gutter="20" class="examQuestionsManageTop">
        <el-col
          :span="8"
          v-for="(item, i) in this.$store.state.public_data.sysSubjectDicList"
          :key="item.name"
        >
          <div
            class="subjectBox"
            @click="clickBtn(i, item.value)"
            :class="checkedNum == i + 1 ? 'active' : ''"
            style="font-size: 20px"
          >
            {{ item.label }}考题
            <!-- <span>
              {{ item.subjectTotal }}
              <i>道</i>
            </span> -->
            <div class="iconBig">
              <img :src="require(`../../../assets/${i + 1}.png`)" />
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-row>
        <el-table
          v-loading="dialogVisibleLoading"
          border
          ref="dialogVisibleTableData"
          :data="dialogVisibleTableData"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="sysSubjectDicId" label="科目">
            <template slot-scope="scope">
              {{ subjectTypeFilters(scope.row.sysSubjectDicId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="resExperimentCourseName"
            label="课程名称"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolRoomName"
            label="上课地点"
          ></el-table-column>
          <el-table-column prop="schoolTime" label="上课日期">
            <template slot-scope="scope">
              {{ scope.row.schoolTime | dayFilters('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column prop="sysSchoolClassSession" label="节次">
            <template slot-scope="scope">
              <span
                v-for="item in scope.row.sysSchoolClassSession"
                :key="item.sysSchoolClassSessionId"
                >{{ item.sysSchoolClassSessionName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassName"
            label="上课班级"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassStudentCount"
            label="班级人数"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="teacherName"
            label="任课老师"
            width="90"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="dialogVisiblePage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="dialogVisiblePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogVisiblePage.totalSize"
        ></el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOpenPlanStatisticsRankingsPageApi,
  getOpenPlanStatisticsPageApi,
} from '@/api/courseStart/startClassStatistics.js'
export default {
  name: 'courseRanking',
  data() {
    return {
      rangeType: null,
      sortFieldType: null,
      loading: false,
      conditionForm: {
        sysOrgSchoolId: '',
      },
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 查看详情
      dialogVisible: false,
      dialogVisibleLoading: false,
      dialogVisibleTableData: [],
      dialogVisibleParam: {
        sysOrgSchoolId: '',
        sysSubjectDicId: '',
      },
      dialogVisiblePage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      //选中
      checkedNum: 0,
      // name:'课程开出排名'带开展课程数排名
    }
  },
  computed: {},
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    this.$store.dispatch('getAllSchoolList')
    // 科目
    // this.subjectList = this.$store.state.public_data.sysSubjectDicList;
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')
    this.conditionForm.sysOrgSchoolId = ''
    this.rangeType = Number(this.$route.query.rangeType)
    this.sortFieldType = Number(this.$route.query.sortFieldType)
    this.conditionForm = {
      rangeType: this.rangeType,
      sortFieldType: this.sortFieldType,
      sortType: 2,
      // sysOrgSchoolId: 0,
    }
    this.changeConditionForm()
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 课程开展排行榜
    async getOpenPlanStatisticsRankingsPageApiFunc(param) {
      try {
        const res = await getOpenPlanStatisticsRankingsPageApi(
          this.page.pageIndex,
          this.page.pageSize,
          param,
          this.rangeType
        )
        console.log('课程开展排行榜', res)
        this.page.totalSize = res.total
        if (res.success) {
          return res.data
        } else {
          this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    async changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.tableData = await this.getOpenPlanStatisticsRankingsPageApiFunc(
        conditionObj
      )
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    resetBtn() {
      this.conditionForm.sysOrgSchoolId = ''
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 排序触发
    changeSort({ column, prop, order }) {
      if (order === 'ascending') {
        this.conditionForm.sortType = 1
      } else {
        this.conditionForm.sortType = 2
      }
      this.inquireBtn()
    },
    // 排名样式
    setIndexStyle(key) {
      switch (key) {
        case 1:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_first.png')}">`
        case 2:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_second.png')}">`
        case 3:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_third.png')}">`
        default:
          return `<span
          style="display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          line-height: 30px;
          background: #DADCDF;
          ">${key}</span>`
      }
    },
    getOpenPlanStatisticsPageApiFunc(param) {
      this.dialogVisibleLoading = true
      getOpenPlanStatisticsPageApi(
        this.page.pageIndex,
        this.page.pageSize,
        param,
        this.rangeType
      )
        .then((res) => {
          this.dialogVisibleLoading = false
          if (res.success) {
            this.dialogVisibleTableData = res.data
            this.dialogVisiblePage.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
          this.dialogVisible = true
        })
        .catch((err) => {})
    },
    // 查看详情
    detailBtn(row) {
      this.dialogVisibleParam.sysOrgSchoolId = row.sysOrgSchoolId
      this.dialogVisibleChangeConditionForm()
    },
    dialogVisibleChangeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.dialogVisibleParam))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getOpenPlanStatisticsPageApiFunc(conditionObj)
    },
    // 切换
    clickBtn(i, subjectType) {
      if (this.checkedNum == i + 1) {
        this.checkedNum = 0
        this.dialogVisibleParam.sysSubjectDicId = undefined
      } else {
        this.checkedNum = i + 1
        this.dialogVisibleParam.sysSubjectDicId = subjectType
      }
      this.dialogVisiblePage.pageIndex = 1
      this.dialogVisibleChangeConditionForm()
    },
    //currentPage 改变时会触发
    dialogVisibleHandleCurrentChange(val) {
      this.dialogVisiblePage.pageIndex = val
      this.dialogVisibleChangeConditionForm()
    },
    //pageSize 改变时会触发
    dialogVisibleHandleSizeChange(val) {
      this.dialogVisiblePage.pageIndex = 1
      this.dialogVisiblePage.pageSize = val
      this.dialogVisibleChangeConditionForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.examQuestionsManageTop {
  border-bottom: 1px solid #c8dbec;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.subjectBox {
  width: 100%;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  border: 2px solid #09c0c800;
  cursor: pointer;
  height: 70px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 6px;
  padding-left: 66px;
  position: relative;

  .iconBig {
    // background-image: linear-gradient(to bottom, #2885da, #6eb7fc);
    width: 52px;
    height: 52px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    // i {
    //   font-size: 30px;
    //   color: white;
    // }
    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    display: block;
    color: #409eff;
    font-size: 26px;

    i {
      font-style: normal;
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
  }

  &:hover {
    background: rgb(213, 213, 213);
  }

  &.active {
    border: 2px solid #409eff;
  }
}
</style>
